var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    class: ['reply-box', _vm.data.is_sender === 1 ? 'you' : 'user']
  }, [_c('div', {
    staticClass: "reply-sign"
  }), _vm._v(" "), _c('div', {
    staticClass: "reply-content"
  }, [_c('div', {
    staticClass: "reply-name"
  }, [_vm._v(_vm._s(_vm.data.is_sender === 1 ? 'You' : _vm.data.nickname))]), _vm._v(" "), [1, 2, 4, 5].includes(_vm.data.msg_type) && !_vm.data.talk_records_file.length ? _c('div', {
    staticClass: "reply-text mgt5"
  }, [_c('el-popover', {
    attrs: {
      "placement": "left",
      "width": "400",
      "trigger": "click",
      "content": _vm.data.content
    }
  }, [_c('div', {
    staticClass: "reply-text cursor-hand",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_vm._v(_vm._s(_vm.data.content))])])], 1) : _vm._e(), _vm._v(" "), _vm.data.talk_records_file.length && _vm.data.talk_records_file[0].file_type === 6 ? _c('div', {
    staticClass: "reply-text mgt5"
  }, [_vm._v("\n            [Picture]\n        ")]) : _vm._e(), _vm._v(" "), _vm.data.msg_type === 3 ? _c('div', {
    staticClass: "reply-order mgt5"
  }, [_c('p', {
    staticClass: "reply-text"
  }, [_vm._v("Order No: " + _vm._s(_vm.data.extra_params.no) + " ")]), _vm._v(" "), _c('p', {
    staticClass: "reply-text"
  }, [_vm._v("Order time: " + _vm._s(_vm.transTimeType(_vm.data.extra_params.create_time, '1')))])]) : _vm._e(), _vm._v(" "), _vm.data.talk_records_file.length && _vm.data.talk_records_file[0].file_type !== 6 ? _c('div', {
    staticClass: "reply-flie mgt5 cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.$emit('dowloadFile', _vm.data);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/file2.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "reply-text"
  }, [_vm._v(_vm._s(_vm.data.talk_records_file[0].file_name))])]) : _vm._e()]), _vm._v(" "), _vm.data.talk_records_file.length && _vm.data.talk_records_file[0].file_type === 6 ? _c('div', {
    staticClass: "reply-img cursor-hand"
  }, [_c('img', {
    attrs: {
      "src": _vm.ossUrl + _vm.data.talk_records_file[0].file_url,
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onLookImg', _vm.data.talk_records_file);
      }
    }
  })]) : _vm._e(), _vm._v(" "), !_vm.hideClose ? _c('div', {
    staticClass: "close-icon cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/close.svg"),
      "alt": ""
    }
  })]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }