import Vue from 'vue'
import 'element-ui/lib/theme-chalk/display.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import {
  Row,
  Col,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Button,
  Cascader,
  Pagination,
  Loading,
  Input,
  InputNumber,
  Form,
  FormItem,
  Select,
  Option,
  Alert,
  Notification,
  Upload,
  Table,
  Tabs,
  TabPane,
  Radio,
  RadioButton,
  RadioGroup,
  Checkbox,
  TableColumn,
  Steps,
  Step,
  Menu,
  MenuItem,
  MenuItemGroup,
  Dialog,
  Message,
  MessageBox,
  Tag,
  DatePicker,
  Card,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Submenu,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  popover,
  Progress,
  CheckboxGroup,
  CascaderPanel,
  Badge,
  Backtop,
  InfiniteScroll,
  Tooltip,
  Timeline,
  TimelineItem,
  Rate,
  Switch,
  Divider,
  Link,
  Image,
  Drawer,
  OptionGroup,
  Autocomplete,
  Skeleton,
  SkeletonItem,
  Slider
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
Vue.prototype.$ELEMENT = { size: 'normal' }
Vue.use(Skeleton)
Vue.use(Slider)
Vue.use(SkeletonItem)
Vue.use(Container)
Vue.use(Autocomplete)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Row)
Vue.use(Col)
Vue.use(Cascader)
Vue.use(Pagination)
Vue.use(Row)
Vue.use(Col)
Vue.use(Loading)
Vue.use(Alert)
Vue.use(Upload)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(TableColumn)
Vue.use(Button)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(DatePicker)
Vue.use(Card)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Submenu)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(popover)
Vue.use(Progress)
Vue.use(CheckboxGroup)
Vue.use(CascaderPanel)
Vue.use(Badge)
Vue.use(Backtop)
Vue.use(InfiniteScroll)
Vue.use(Tooltip)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Rate)
Vue.use(Switch)
Vue.use(Divider)
Vue.use(Link)
Vue.use(Image)
Vue.use(Drawer)
Vue.use(OptionGroup)
Vue.use(RadioButton)
locale.use(lang)
Vue.prototype.$notify = Notification
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$message = Message
const handleBlur = Select.methods.handleBlur
const handleFocus = Select.methods.handleFocus
//重写Select下拉框失焦的方法
Select.methods.handleBlur = function(event) {
  this.relatedTarget = event.relatedTarget
  this.lastHoverIndex = this.hoverIndex
  handleBlur.call(this, event)
}
//重写初始换下拉选择hover索引
Select.methods.resetHoverIndex = function(obj) {
  if (obj) {
    this.hoverIndex = obj.index
    return
  }
  this.$nextTick(() => {
    if (!this.multiple) {
      this.hoverIndex = this.options.indexOf(this.selected)
    } else {
      if (this.selected.length > 0) {
        this.hoverIndex = Math.min.apply(
          null,
          this.selected.map(item => this.options.indexOf(item))
        )
      } else {
        this.hoverIndex = -1
      }
    }
  })
}
Select.methods.handleFocus = function(event) {
  this.relatedTarget = null
  handleFocus.call(this, event)
}
// 图片预览插件
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: false,
    toolbar: false,
    tooltip: false,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: false,
    keyboard: true,
    url: 'data-source',
    zIndex: 999999
  }
})

Vue.directive('prevent-right-click', {
  bind: function(el) {
    el.addEventListener('contextmenu', function(event) {
      event.preventDefault()
    })
  }
})
