<!--  -->
<template>
    <main :class="['reply-box',data.is_sender === 1?'you':'user']" >
        <div class="reply-sign"></div>
        <div class="reply-content">
            <div class="reply-name">{{ data.is_sender === 1?'You': data.nickname}}</div>
            <div class="reply-text mgt5" v-if="[1,2,4,5].includes(data.msg_type) && !data.talk_records_file.length">
                <el-popover
                    placement="left"
                    width="400"
                    trigger="click"
                    :content="data.content">
                    <div class="reply-text cursor-hand" slot="reference">{{ data.content }}</div>
                </el-popover>
            </div>
            <div class="reply-text mgt5" v-if="data.talk_records_file.length && data.talk_records_file[0].file_type === 6">
                [Picture]
            </div>
            <div class="reply-order mgt5" v-if="data.msg_type === 3">
                <p class="reply-text">Order No: {{ data.extra_params.no }} </p>
                <p class="reply-text">Order time: {{ transTimeType(data.extra_params.create_time,'1') }}</p>
            </div>
            <div class="reply-flie mgt5 cursor-hand" @click="$emit('dowloadFile',data)" v-if="data.talk_records_file.length && data.talk_records_file[0].file_type !== 6">
                <img src="~assets/images/file2.svg" alt="">
                <span class="reply-text">{{ data.talk_records_file[0].file_name }}</span>
            </div>
        </div>
        <div class="reply-img cursor-hand" v-if="data.talk_records_file.length && data.talk_records_file[0].file_type === 6">
            <img @click="$emit('onLookImg',data.talk_records_file)"  :src="ossUrl + data.talk_records_file[0].file_url" alt="">
        </div>
        <div v-if="!hideClose" class="close-icon cursor-hand" @click="$emit('close')">
            <img src="~assets/images/close.svg" alt="">
        </div>
    </main>
</template>

<script>
import { transTimeType } from '~/libs/tool'
export default {
    name: 'replyView',
    props: {
        data:{
            type: Object,
            default: ()=>{}
        },
        hideClose:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        ossUrl: function() {
            return process.env.baseOss
        }
    },
    data() {
        return {        }
    },
    mounted() {},
    methods: {
        transTimeType
    },
}
</script>
<style lang='scss' scoped>
    .reply-box{
        width: 100%;
        height: auto;
        background-color: #F5F5F5;
        border-radius: 8px;
        display: flex;
        .reply-sign{
            width: 5px;
            height: inherit;
            border-radius: 8px 0 0 8px;
            background-color: #5BBAFF;
        }   
        .reply-content{
            width: 100%;
            box-sizing: border-box;
            flex: 1;
            padding: 10px 20px;
            padding-right: 0px;
            overflow: hidden;
            .reply-name{
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }
            .reply-text{
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #878787;
                word-break:break-all; 
                display: -webkit-box; /* 设置为弹性盒模型，支持多行截断 */
                -webkit-line-clamp: 2; /* 限制显示两行 */
                -webkit-box-orient: vertical; /* 设置盒子方向为垂直 */
                overflow: hidden; /* 超出内容隐藏 */
                text-overflow: ellipsis; /* 溢出部分显示省略号 */
            }
            .reply-flie{
                display: flex;
                align-items: center;
                img{
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
        .reply-img{
            width: 40px;
            display: flex;
            align-items: center;
            padding: 0 13px;
            img{
                width: 40px;
                height: 40px;
            }
        }
        .close-icon{
            padding: 10px;
            padding-left: 0px;
            width: 18px;
            height: inherit;
            img{
                width: 18px;
                height: 18px;
            }
        }

    }
    .you{
        .reply-sign{
            background-color: rgba(234, 84, 63, 1);
        }
        .reply-name{
            color: rgba(234, 84, 63, 1);
        }
    }

    .user{
        .reply-sign{
            background-color: rgba(91, 186, 255, 1);
        }
        .reply-name{
            color: rgba(91, 186, 255, 1);
        }
    }
</style>
