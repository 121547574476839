var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('el-skeleton', {
    attrs: {
      "loading": _vm.loading,
      "rows": 6,
      "animated": ""
    }
  }) : _c('el-container', [_vm.showTip && _vm.showLeftSidebarAndHeader ? _c('div', {
    staticClass: "lead-time-tip"
  }, [_c('svg-icon', {
    attrs: {
      "iconClass": "Subtract",
      "width": _vm.px2rem('20px'),
      "height": _vm.px2rem('20px')
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "mgl10"
  }, [_vm._v("\n      RapidDirect will be on holiday from Jan. 25th to Feb. 2nd for Chinese New Year. Quotes and orders remain open, but lead times may extend. Please plan ahead!\n    ")]), _vm._v(" "), _c('span', {
    staticClass: "el-icon-close mgl10 cursor-hand",
    on: {
      "click": _vm.close
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('el-header', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLeftSidebarAndHeader,
      expression: "showLeftSidebarAndHeader"
    }],
    attrs: {
      "height": "auto"
    }
  }, [_c('header-home')], 1), _vm._v(" "), _vm.showLeftSidebarAndHeader ? _c('el-container', {
    staticClass: "layout-container-box",
    class: {
      'layout-container-box-tip': _vm.showTip
    },
    attrs: {
      "id": "el-container"
    }
  }, [_c('el-aside', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.auth && _vm.$route.path != '/help-center/specifications-policy',
      expression: "auth&&$route.path!='/help-center/specifications-policy'"
    }],
    key: _vm.$route.path,
    class: [_vm.openAside ? 'aside-open' : 'aside-close']
  }, [_c('aside-home')], 1), _vm._v(" "), _c('div', {
    staticClass: "layout-container",
    attrs: {
      "id": "layout-container"
    }
  }, [_c('nuxt')], 1), _vm._v(" "), _c('OnlineChat', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showTeamChat,
      expression: "showTeamChat"
    }]
  })], 1) : _vm._e(), _vm._v(" "), !_vm.showLeftSidebarAndHeader ? _c('el-container', {
    staticClass: "layout-container-box noSidebar",
    attrs: {
      "id": "el-container"
    }
  }, [_c('div', {
    staticClass: "layout-container",
    attrs: {
      "id": "layout-container"
    }
  }, [_c('nuxt')], 1)]) : _vm._e(), _vm._v(" "), _c('back-to-top'), _vm._v(" "), _c('find-password'), _vm._v(" "), _c('reset-password'), _vm._v(" "), _c('dialog-login'), _vm._v(" "), _c('register'), _vm._v(" "), _c('thankYou'), _vm._v(" "), _c('message'), _vm._v(" "), _c('CreateTeamDialog')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }