// preview.js

import PreviewItem from "./PreviewImage.vue";

const Preview = {};

// 注册
Preview.install = function(Vue) {
  const PreviewConstructor = Vue.extend(PreviewItem);
  const instance = new PreviewConstructor();
  
  // /**
  //  * 挂载在vue原型上
  //  * @param {Array} imgs 需要预览的图片数组
  //  */
  Vue.prototype.$openPreview = function(imgs = [], currentIndex = 0) {
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);
    instance.currentIndex = currentIndex;
    instance.showPreview = true;
    instance.previewImages = imgs;
  };
};

export default Preview;