<template>
  <el-backtop :bottom="100" :right="33">
    <div
      style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        color: #1989fa;
      }"
    >
      <i class="backtop"></i>
    </div>
  </el-backtop>
</template>

<script>
export default {
  name: 'BackToTop'
}
</script>
<style>
.backtop {
  display: block;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('~assets/images/arrow_t.png');
  transform: rotate(180deg);
}
</style>
