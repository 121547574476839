export const state = () => ({
  createTeamDialogVisible: false, //判断是否打开创建团队弹窗
  createTeamDetailId: '', //当前团队详情id
  createTeamListData: '', //团队列表数据
  loginCreateTeamVisible: '', //未登录的情况下 点击创建团队按钮必须先登录之后弹出创建团队
  isfirstTeamUser: false, //判断是否为创建或加入团队的新用户
  statusListData: [], //team 列表数据储存
  isShowTeamChat: true, //不是询价单创建者不显示聊天窗口
  addTeamQuoteId: "", //创建团队时 有询价id 自动加入此询价单
  addTeamOrderId: "", //创建团队时 有询价id 自动加入此询价单
})

export const mutations = {
  openCreateTeam(state, createTeamDialogVisible) {
    state.createTeamDialogVisible = createTeamDialogVisible
  },
  //未登录的情况下 点击创建团队按钮必须先登录之后弹出创建团队
  openLoginCreateTeam(state, loginCreateTeamVisible) {
    state.loginCreateTeamVisible = loginCreateTeamVisible
  },
  setCreateTeamListData(state, createTeamListData) {
    state.createTeamListData = createTeamListData
  },
  setCreateTeamDetailId(state, createTeamDetailId) {
    state.createTeamDetailId = createTeamDetailId
  },
  setIsfirstTeamUser(state, isfirstTeamUser) {
    state.isfirstTeamUser = isfirstTeamUser
  },
  setStatusListData(state, statusListData) {
    state.statusListData = statusListData
  },
  setIsShowTeamChat(state, isShowTeamChat) {
    state.isShowTeamChat = isShowTeamChat
  },
  setTeamQuoteId(state, addTeamQuoteId) {
    state.addTeamQuoteId = addTeamQuoteId
  },
  setTeamOrderId(state, addTeamOrderId) {
    state.addTeamOrderId = addTeamOrderId
  }
}
