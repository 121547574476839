<template>
  <div class="address-win">
    <el-dialog 
      :visible.sync="show" 
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="close"
    >

    <div class="dialog-win">
        <div class="dialog-left">
          <img :style="{width: '100%',height: '100%'}"  src="@/assets/images/forgotPassword_bg.svg" />
        </div>
        <div class="dialog-right">
          <div class="dialog-close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="align-center" style="height:100%">
          <div class="login-content">
        <div class="login-title"  v-if="findState === 0">
          <div>Forgot Password</div>
          <div class="little-title">Enter your email address to receive email with verification code</div>
        </div>
        <div class="login-title"  v-else >
          <div>Verify Email</div>
          <div class="little-title little-tittle-1">The verification code has been sent to your email. Please enter the verification code.</div>
        </div>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          label-position="top"
          @submit.native.prevent
        >
          <el-form-item v-if="findState === 0" prop="email" label="Email address">
            <el-input v-model="ruleForm.email" placeholder="Enter your email address"></el-input>
            <template slot="error" slot-scope="scope">
              <span class="form__error_icon_tip">
                <i class="el-icon-error"></i>
                <span >{{ scope.error }}</span>
              </span>
            </template>
          </el-form-item>
          <div class="verifyCodeDiv" v-else>
            <input
              v-for="(code, index) in verificationCodes"
              :key="index"
              v-model="verificationCodes[index]"
              @input="handleInput(index, $event)"
              @keydown="handleKeyDown(index, $event)"
              maxlength="1"
              :class="index == 5 ? 'verification-input verification-input-last' : 'verification-input'"
            />
          </div>
          <!-- <el-form-item v-else prop="verify" class="verify">
            <div class="code_input">
              <div class="verInputBg verInputBg1"></div>
              <div class="verInputBg verInputBg2"></div>
              <div class="verInputBg verInputBg3"></div>
              <div class="verInputBg verInputBg4"></div>
              <div class="verInputBg verInputBg5"></div>
              <div class="verInputBg verInputBg6"></div>
              <el-input ref="verifyInput" v-model="ruleForm.verify" :maxlength="6"></el-input>
            </div>
          </el-form-item> -->
          <div class="loginBtnBox" v-if="findState === 0">
            <el-button
              class="loginBtn"
              type="primary"
              size="large"
              :loading="getVerifyLoading"
              @click="sendGetVerify('ruleForm')"
            >Send</el-button>
          </div>
          <div class="loginBtnBox" v-else>
            <div v-if="findState !== 0" class="loginBtnBox-txt">
              <div
                v-if="!isCountdown"
                class="cfont tr cursor-hand"
                @click="getResend('ruleForm')"
              >
                Didn’t receive a code? 
                <span class="Resend">Resend</span>
              </div>
              <div v-else class="tr">{{ countdown }} s</div>
            </div>
            <el-button
              class="loginBtn"
              type="primary"
              size="large"
              :loading="nextLoading"
              :disabled="!(ruleForm.verify && ruleForm.verify.length == 6)"
              @click="next('ruleForm')"
            >Verify</el-button>
          </div>
        </el-form>
        </div>
        </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { px2rem,debounce } from '~/libs/tool'
export default {
  name: 'FindPassword',
  data() {
    return {
      show: false,
      getVerifyLoading: false,
      nextLoading: false,
      timer: null,
      countdown: 120,
      isCountdown: false,
      findState: 0,
      verificationCodes: ['','','','','',''],
      ruleForm: {
        type: 'e',
        email: '',
        verify: ''
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Please enter your email address',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Please enter the correct email address',
            trigger: ['blur', 'change']
          }
        ],
        verify: [
          {
            required: true,
            message: 'Please enter the verification code',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  watch: {
    // 'ruleForm.verify': function(newVal) {
    //   if (newVal.length === 6) {
    //     this.$nextTick(() => {
    //       const verifyInput = this.$refs.verifyInput
    //       verifyInput.$refs.input.setSelectionRange(5, 5)
    //     })
    //   }
    // },
    '$store.state.findPassword': function(val) {
      this.show = val;
    },
    show: function(e) {
      if(!e) {
        this.findState = 0;
        this.ruleForm = {
          type: 'e',
          email: '',
          verify: ''
        }
        this.verificationCodes = ['','','','','','']
      }
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    px2rem,
    // 验证码输入
    handleInput(index,event) {
      let vm = this;
      const value = event.target.value;
      vm.verificationCodes[index] = value;
      vm.ruleForm.verify = vm.verificationCodes.join('');
      // 自动调到下一步输入框
      if(value && index < (vm.verificationCodes.length - 1)) {
        const nextInput = event.target.nextElementSibling;
        if(nextInput) {
          this.$nextTick(() => {
            nextInput.focus();
          })
        }
      }
    }, 
    handleKeyDown(index,event) {
      const prevInput = event.target.previousElementSibling;
      // 处理删除操作
      if (event.key === 'Backspace' && !event.target.value && index > 0) {
        if (prevInput) {
          this.$nextTick(() => {
            prevInput.focus();
          });
        }
      }
    }, 
    sendGetVerify(formName) {
      this.$refs[formName].validateField('email', error => {
        if (!error) {
          this.getVerify()
        }
      })
    },
    getVerify() {
      // 检测账号是否存在
      const data = {
        user: this.ruleForm.email
      }
      this.$detectionUser(data).then(res => {
        if (res.code === 1) {
          this.nextLoading = false
          this.$notify.error({
            title: 'Error',
            customClass: 'notify-error',
            message: 'The account does not exist'
          })
        } else {
          this.getVerifyLoading = true
          const data = {
            type: this.ruleForm.type,
            user: this.ruleForm.email,
            genre: 'forget'
          }
          this.$getVerify(data).then(res => {
            if (this.timer) {
              clearInterval(this.timer)
            }
            this.isCountdown = true
            this.timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--
              } else {
                clearInterval(this.timer)
                this.countdown = 120
                this.isCountdown = false
              }
            }, 1000)
            this.$notify.success({
              title: 'Success',
              customClass: 'notify-success',
              message:
                'The verification code has been sent to your email address. Please check it.'
            })
            this.findState = 1
            this.getVerifyLoading = false
          })
        }
      })
    },
    getResend: debounce(function (form) {
      this.getVerify(form)
    },300),
    next(formName) {
      this.nextLoading = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          //  检测验证码是否正确
          const data = {
            type: this.ruleForm.type,
            code: this.ruleForm.verify,
            accounts: this.ruleForm.email
          }
          this.$spyVerify(data).then(res => {
            this.nextLoading = false
            if (res.code === 1) {
              /**
               * 验证码正确
               * 采用vuex的Action执行异步存储用户账户
               * vuex成功存储用户账户之后路由跳转进入下一页
               */
              this.$store
                .dispatch('resetPass/setEmail', this.ruleForm.email)
                .then(res => {
                  this.findState = 0
                  this.ruleForm.verify = ''
                  this.$store.commit('openFindPassword', false)
                  this.$store.commit('openResetPassword', true)
                })
            } else {
              this.nextLoading = false
              this.$notify.error({
                title: 'Error',
                customClass: 'notify-error',
                message: 'Verification code error'
              })
            }
          })
        } else {
          return false
        }
      })
    },
    close() {
      this.$store.commit('openFindPassword', false)
      let home = false
      const rt = this.$route.path
      rt === '/' ||
      this.$store.state.indexRouters.includes(this.$route.path) ||
      rt === '/member/findPassword' ||
      rt === '/member/resetPassword'
        ? (home = false)
        : (home = true)
      if (home) {
        this.$router.push('/')
      }
    }
  }
}
</script>
<style scoped lang="scss">
.address-win /deep/ .el-dialog__wrapper .el-dialog {
  width: 1196px;
  height: 720px;
  font-weight: 400;
  font-family: Poppins;
  font-style: normal;
}
.dialog-win {
  background: #ffffff;
  box-sizing: border-box;
  overflow: auto;
  z-index: 1013;
  min-width: 1100px;
  display: flex;
  align-items: center;
}
.dialog-close {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  text-align: center;
  color: #000000;
  line-height: 26px;
  cursor: pointer;
}
.address-win /deep/ .el-dialog__header,
.address-win /deep/ .el-dialog__body {
  border: none;
  padding: 0;
}
.dialog-left{
  width: 576px;
  height: 720px;
}
.dialog-right{
   width: 620px;
   display: flex;
   align-items: center;
   justify-content: center;
  .login-content{
    width: 500px;
    margin: 0 60px;
    .login-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    color: #252525;
    text-align: center;
    margin: 0 0 40px;
    .little-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 20px;
    }
    .little-tittle-1 {
      width: 376px;
    }
   }
  }
}
.demo-ruleForm {
  overflow: hidden;
  /deep/ input:-webkit-autofill {
    -webkit-text-fill-color: #252525;
  }
  /deep/ input::-webkit-input-placeholder {
    color: #878787;
  }
  /deep/ input::-moz-input-placeholder {
    color: #878787;
  }
  /deep/ input::-ms-input-placeholder {
    color: #878787;
  }
  /deep/ .el-form-item.is-error .el-input__inner {
    border-color: #EA543F;
  }
  /deep/ .el-form-item__content{
    line-height: normal;
  }
  /deep/ .el-form-item__error {
    padding-top: 8px;
    color: #EA543F;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
  /deep/ .el-form-item__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #252525;
    padding-bottom: 8px;
    &:after {
          content: '*';
          color: #F56C6C;
          margin-left: 0.02083rem;
      }
  }
  .el-form-item {
    margin-bottom: 40px;
  }
  /deep/ .el-input__inner {
    height: 38px;
    line-height: 38px;
    color: #252525 !important;
  }
}
.loginBtnBox {
  widows: 100%;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #EA543F;
  .loginBtnBox-txt {
    margin-bottom: 20px;
  }
  .Resend {
    display: inline-block;
    text-decoration-line: underline;
  }
  .loginBtn {
    font-size: 14px;
    line-height: 22px;
    padding: 8px 64px;
    border: 0!important;
  }
}
.footer {
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  .forgot {
    color: #EA543F;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .margin-b-10 {
    margin-bottom: 10px;
  }
  .dialog-mess {
    color: #999;
  }
}

.demo-ruleForm /deep/ .el-form-item__error {
  position: relative;
  top: 0;
}
.verify /deep/ .el-form-item__error {
  text-align: center;
}
.verifyCodeDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .verification-input {
    width: 40px;
    height: 40px;
    margin-right: 25px;
    text-align: center;
    font-size: 14px;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
  }

  .verification-input-last {
    margin-right: 0;
  }

}
</style>
