import jsPDF from 'jspdf'
import logo from './logo-img.png'
import moldImg from './mould_default.png'
import RDSeal from './RDSeal.png'
import baseBoldFont from './chinaFont/baseBoldFont'
import baseFont from './chinaFont/baseFont'
import fangzhengfangsong from './chinaFont/fangzhengfangsong-normal'
import heiti from './chinaFont/heiti-normal'
import { multilineText, format } from './utils'
class PI {
  constructor({ request, ossUrl }) {
    this.request = request
    this.ossUrl = ossUrl
    // 当前页面位置
    this.currentPage = 1
    // 总页面
    this.total = 1
    // 开头x轴
    this.baseX = 15
    // 记录零件列表中每一项x轴的位置
    this.partsTh = [this.baseX + 2, 46, 137, 150, 173]
    // 记录模具列表中每一项x轴的位置
    this.moldTh = [this.baseX + 2, 38, 68, 91, 120, 137, 150, 173]
    // 记录页面所在Y轴
    this.currentY = 0
    //
    this.maxPageY = 260
    this.doc = new jsPDF()
  }
  // 字符串分割指定长度的数组
  splitStr(str, length) {
    const arr = []

    let index = 0
    while (index < str.length) {
      arr.push(str.slice(index, (index += length)))
    }

    return arr
  }

  getImgCanvas(url, bg = '#ffffff') {
    return new Promise(resolve => {
      const img = new Image()
      img.src = url
      img.style.backgroundColor = bg

      img.onload = e => {
        const imgWidth = img.width
        const imgHeight = img.height

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = imgWidth
        canvas.height = imgHeight
        ctx.fillStyle = bg
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight)
        resolve({
          img: canvas.toDataURL('image/jpeg'),
          msg: {
            ratio: imgWidth / imgHeight,
            imgWidth,
            imgHeight
          }
        })
      }
    })
  }

  // 写顶部相关信息
  async writeTop() {
    try {
      const { img } = await this.getImgCanvas(logo)
      this.doc.addImage(img, this.baseX, 14, 40, 14, 'logo', 'NONE')
      this.doc.setTextColor(0, 0, 0)
      this.doc.setFont('BaseBoldFont')
      this.doc.setFontSize(16)
      this.doc.text('COMMERCIAL INVOICE', 191.5, 22, {
        align: 'right',
        charSpace: 0.4
      })
      this.doc.setFont('BaseFont')
      this.doc.setFontSize(8)
      this.doc.text(`# ${this.data.order_no}`, 195, 27, null, null, 'right')

      return Promise.resolve()
    } catch (error) {}
  }
  writeLeftMsg() {
    const baseX1 = this.baseX
    const baseX2 = 40
    let varY = 50
    this.doc.setFontSize(8)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('To:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const to = multilineText(
      this.doc,
      this.data.address.to||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = to.y + 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Atten:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const atten = multilineText(
      this.doc,
      this.data.address.atten||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = atten.y + 5

    this.doc.setTextColor(0, 0, 0)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('E-mail:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const email = multilineText(
      this.doc,
      this.data.address.email||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = email.y + 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Tel:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(this.data.address.tel||'', baseX2, varY, null, null, 'left')
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Shipping:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(this.data.address.shipping||'', baseX2, varY, null, null, 'left')
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Ship To:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const ship = multilineText(
      this.doc,
      this.data.address.ship_to||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = ship.y
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Invoice To:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const invoice = multilineText(
      this.doc,
      this.data.address.invoice_to||'',
      baseX2,
      varY,
      70,
      99
    )
    varY = invoice.y + 5

    if (this.data.order.process_type === 2) {
      this.doc.setFont('BaseBoldFont')
      this.doc.text(
        'Lead Time:',
        baseX1,
        varY,
        null,
        null,
        'left'
      )
      this.doc.setFont('BaseFont')
      this.doc.text(
        `${this.data.order.mould_lead_time} busniess day for molds`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
      this.doc.text(
        `${this.data.order.lead_time} busniess day for parts`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
    } else {
      this.doc.setFont('BaseBoldFont')
      this.doc.text('Lead Time:', baseX1, varY, null, null, 'left')
      this.doc.setFont('BaseFont')
      this.doc.text(
        `${this.data.order.lead_time} Business days`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
    }
    return varY
  }
  writeRightMsg() {
    const baseX1 = 122
    const baseX2 = 150
    this.doc.setFontSize(8)
    let varY = 50

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Invoice Date:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.address.pay_success_time||'',
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.setTextColor(0, 0, 0)
    this.doc.text('PO No.:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const po = multilineText(
      this.doc,
      this.data.address.po_no||'',
      baseX2,
      varY,
      50,
      99
    )
    varY = po.y + 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('PO Date:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.address.po_date||'',
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Manufacturer:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const Manufacturer = multilineText(
      this.doc,
      'Shenzhen Rapid Direct Co., Ltd.',
      baseX2,
      varY,
      50,
      99
    )
    varY = Manufacturer.y + 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Contact:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const contact = multilineText(
      this.doc,
      this.data.address.sale_contact||'',
      baseX2,
      varY,
      50,
      99
    )
    varY = contact.y + 5
    
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Tel:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(this.data.address.sale_tel||'', baseX2, varY, null, null, 'left')
    varY += 5
    
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Email:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const sale_email = multilineText(
      this.doc,
      this.data.address.sale_email||'',
      baseX2,
      varY,
      50,
      99
    )
    varY = sale_email.y + 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Address:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const Address = multilineText(
      this.doc,
      `Building A12, Haosi Industrial Park, Nanpu Road, Xinqiao Street, Bao'an District, Shenzhen, China, 518104`,
      baseX2,
      varY,
      50,
      99
    )
    varY = Address.y + 5

    return varY
  }
  writeMoldTbHeader() {
    const baseY = this.currentY
    this.doc.setFontSize(7)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Mold', this.moldTh[0], baseY, null, null, 'left')
    this.doc.text('Mold No.', this.moldTh[1], baseY, null, null, 'left')
    this.doc.text('Mold Material', this.moldTh[2], baseY, null, null, 'center')
    this.doc.text('Mold life', this.moldTh[3], baseY, null, null, 'left')
    this.doc.text('Cavity', this.moldTh[4], baseY, null, null, 'center')
    this.doc.text('Qty', this.moldTh[5], baseY, null, null, 'center')
    this.doc.text('Unit Price', this.moldTh[6], baseY, null, null, 'left')
    this.doc.text('Price', this.moldTh[7], baseY, null, null, 'left')
  }
  writeBlockBg(y, h, cl) {
    let color = [250, 250, 250]
    if (cl) {
      var r = parseInt(cl.substring(1, 3), 16)
      var g = parseInt(cl.substring(3, 5), 16)
      var b = parseInt(cl.substring(5, 7), 16)
      color = [r, g, b]
    }
    this.doc.setDrawColor(255, 255, 255)
    this.doc.setFillColor(...color)
    this.doc.rect(this.baseX, y, 180, h > 18 ? h : 18, 'FD')
  }
  writePartTbHeader() {
    const baseY = this.currentY
    this.doc.setFontSize(7)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Item', this.partsTh[0], baseY, null, null, 'left')
    this.doc.text('Description', this.partsTh[1], baseY, null, null, 'left')
    this.doc.text('Qty', this.partsTh[2], baseY, null, null, 'center')
    this.doc.text('Unit Price', this.partsTh[3], baseY, null, null, 'left')
    this.doc.text('Price', this.partsTh[4], baseY, null, null, 'left')
  }
  async writePartBlockBg(index, part) {
    // 这里是要画背景矩形，但是由于不知道零件高度多少，所以逻辑是先画零件内容，得到高度后，再根据返回的零件数据的最高y得到矩形的高度
    let originY = this.currentY
    let maxY = 0
    const partRes = await this.writePart(
      index,
      part,
      this.currentY,
      index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
    )
    maxY = partRes.maxY
    if (maxY > this.maxPageY) {
      // 如果当前零件放不下了，那么就要新建页面
      // 把原本的零件用白色矩形隐藏起啦哎
      this.writeBlockBg(this.currentY, maxY - originY + 2, '#ffffff')
      // 添加新的页面
      await this.appendNewPage()
      // 重新再画当前的零件
      const res = await this.writePart(
        index,
        part,
        this.currentY,
        index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
      )
      // 重新赋值
      maxY = res.maxY
      originY = this.currentY
    }
    if (index % 2 === 0) {
      this.writeBlockBg(this.currentY, maxY - originY + 2)
    } else {
      this.writeBlockBg(this.currentY, maxY - originY + 2, '#ffffff')
    }
    this.currentY = originY
  }
  async writePart(index, part, y, bg) {
    this.doc.setFontSize(6)
    this.doc.setFont('BaseBoldFont')
    this.doc.text(index + 1 + '', this.partsTh[0], y + 10, null, null, 'left')

    let img = ''
    let imgBase64 = ''
    let imgUrl = ''
    let imgH = 10

    if (part.img_url) {
      try {
        img = await fetch(this.ossUrl + part.img_url).then(res => {
          if (res.status === 200) {
            return res.blob()
          } else {
            return Promise.reject(res)
          }
        })
      } catch (error) {}
    }

    if (img) {
      imgBase64 = await this.blobToDataURI(img)
      imgUrl = await this.getImgCanvas(imgBase64, bg)
      const w = imgUrl.msg.ratio * imgH
      const ml = (15 - w) / 2
      // 辅助线
      // this.doc.setDrawColor(255, 0, 0);
      // this.doc.rect(this.partsTh[0] + 5, y + 2, 15, 10);
      this.doc.addImage(
        imgUrl.img,
        this.partsTh[0] + 5 + ml,
        y + 2,
        w,
        imgH,
        'part_' + '' + part.id + '' + (index + 1),
        'FAST'
      )
    }

    this.doc.setFontSize(4)
    this.doc.setFont('BaseFont')
    this.doc.text(
      part.size + ' mm',
      this.partsTh[0] + 5,
      y + 14,
      null,
      null,
      'left'
    )

    this.doc.setFontSize(6)
    const name_str = multilineText(
      this.doc,
      part.name ,
      this.partsTh[1],
      y + 4,
      80,
      99,
      3
    );
    this.doc.setFontSize(6)
    // this.doc.text(part.tech_id + '/' + part.mat_id, this.partsTh[1], y + 7, null, null, "left")
    // 处理表处为空情况
    if(part.sur_id) {
      let index = part.sur_id.indexOf('/');
      if(index == 1) {
        let firstPart = part.sur_id.substring(0, index);
        let remainingPart = part.sur_id.substring(index+1);
        firstPart == ' ' ? part.sur_id = remainingPart : ''
      }
    }

    // 处理 RA 表处 为 Other的情况
    function removeOtherPrefix(str) {
      if(!str) return ''
      return str.replace(/other:/g, '');
    }

    this.doc.setFontSize(6);

    const finishStr = part.finish_data_arr.map(item => {
      return item.label && item.name ? item.label : item.name
    }).join(' / ')

    const sur = multilineText(
      this.doc,
      part.tech_id + ' / ' + part.mat_id + (finishStr? ' / ' +  finishStr : '') + (part.smooth_spi_name? ' / '+ removeOtherPrefix(part.smooth_spi_name) : ''),
      this.partsTh[1],
      name_str.y + 3,
      80,
      99,
      3
    )
    // this.doc.text(part.sur_id, this.partsTh[1], sur.y + 10, null, null, "left")

    this.doc.setFontSize(4)

    let descLineH = 2.5
    this.doc.text(
      `• General tolerance: ${part.tolerance}`,
      this.partsTh[1],
      sur.y + descLineH,
      null,
      null,
      'left'
    )
    descLineH += 2.5

    this.doc.text(
      `• Tightest tolerance: ${part.anotherTolerance}`,
      this.partsTh[1],
      sur.y + descLineH,
      null,
      null,
      'left'
    )
    descLineH += 2.5

    if (this.data.order.process_type !== 2) {
      this.doc.text(
        `• Threads and tapped holes: ${part.spiricle || '-'}`,
        this.partsTh[1],
        sur.y + descLineH,
        null,
        null,
        'left'
      )
      descLineH += 2.5
    }

    this.doc.setFontSize(6)
    this.doc.text(part.num + '', this.partsTh[2], y + 10, null, null, 'center')

    this.doc.text(
      part.ori_unit_price,
      this.partsTh[3],
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(part.ori_price, this.partsTh[4], y + 10, null, null, 'left')

    return Promise.resolve({
      maxY: sur.y + 8 >= y + imgH + 7.5 ? sur.y + 8 : y + imgH + 7.5
    })
  }
  async writeMold(mold, y) {
    this.doc.setFontSize(6)
    this.doc.setFont('BaseBoldFont')
    const { img } = await this.getImgCanvas(moldImg, 'rgb(250, 250, 250)')
    this.doc.addImage(img, this.moldTh[0] + 2, y + 5, 10, 10, 'mold', 'NONE')
    // this.doc.addImage(moldImg, "JPEG", this.moldTh[0] + 2, y + 5, 10, 10);

    this.doc.setFontSize(6)
    this.doc.setFont('BaseFont')
    this.doc.text(mold.mould_no, this.moldTh[1], y + 10, null, null, 'left')

    this.doc.text(
      mold.mould_material,
      this.moldTh[2],
      y + 10,
      null,
      null,
      'center'
    )
    // this.doc.setDrawColor(255, 0, 0);
    // this.doc.rect(this.moldTh[2] - 6, y, 13, 18);

    this.doc.text(
      mold.mould_dura_max ? mold.mould_dura_max + ' K' : '',
      this.moldTh[3] + 3,
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(
      mold.mould_hole_num,
      this.moldTh[4],
      y + 10,
      null,
      null,
      'center'
    )
    let mouldNum = null;
    let mouldPrice = null;
    let totalMouldPrice = null;
    if(this.data.order.is_return) {
      mouldNum = '-'
      mouldPrice = '-'
      totalMouldPrice = '-';
    }else {
      mouldNum = mold.mould_num
      mouldPrice = mold.mould_price
      totalMouldPrice = mold.mould_total_price
    }
    this.doc.text(
      mouldNum + '',
      this.moldTh[5],
      y + 10,
      null,
      null,
      'center'
    )
    this.doc.text(
      mouldPrice + '',
      this.moldTh[6] + 1,
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(
      totalMouldPrice + '',
      this.moldTh[7] + 1,
      y + 10,
      null,
      null,
      'left'
    )
    return Promise.resolve()
  }
  // 写底部页脚
  writeFooter(cb) {
    this.doc.setLineWidth(0.5)
    this.doc.setDrawColor(0, 0, 0)
    this.doc.line(195, 285, this.baseX, 285)

    this.doc.setFontSize(10)
    this.doc.setFont('BaseFont')
    this.doc.setTextColor(0, 0, 0)

    this.doc.textWithLink('www.rapiddirect.com', this.baseX, 290, {
      url: 'https://www.rapiddirect.com/'
    })

    this.doc.text(
      `Page:${this.currentPage}/${this.total}`,
      195,
      290,
      null,
      null,
      'right'
    )

    if (this.currentPage !== 1) {
      setTimeout(() => {
        // 这里需要写递归不断向上写页脚
        this.currentPage -= 1
        this.doc.movePage(this.currentPage, this.currentPage + 1)
        this.writeFooter(cb)
      }, 100)
    } else {
      // 这里代表已经写完页脚，需要把位置改为原本的位置
      this.currentPage = this.total
      this.doc.movePage(1, this.total)
      cb()
    }
  }
  async writeData() {
    if ((this.data.order.process_type === 2 || this.data.order.is_return) && this.data.mould_data.length) {
      // 注塑
      for (let i = 0; i < this.data.mould_data.length; i++) {
        const item = this.data.mould_data[i]
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
        if (i !== 0) {
          this.currentY += 10
        }
        this.writeMoldTbHeader()
        this.currentY += 3
        this.writeBlockBg(this.currentY)
        await this.writeMold(item, this.currentY)
        this.currentY += 23
        const part = this.data.order.order_parts_list.filter(
          r => r.mould_id === item.id
        )
        for (let j = 0; j < part.length; j++) {
          const r = part[j]
          const k = j
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
          if (k === 0) {
            this.writePartTbHeader()
          }
          this.currentY += 3
          if (k % 2 === 0) {
            this.writeBlockBg(this.currentY)
          }
          await this.writePartBlockBg(k, r)
          const res = await this.writePart(
            k,
            r,
            this.currentY,
            k % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
          )
          this.currentY = res.maxY + 2
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
        }
        this.currentY += 8
      }
    } else {
      // 非注塑
      this.writePartTbHeader()
      this.currentY += 3
      for (let i = 0; i < this.data.order.order_parts_list.length; i++) {
        const index = i
        const item = this.data.order.order_parts_list[i]
        await this.writePartBlockBg(index, item)
        const res = await this.writePart(
          index,
          item,
          this.currentY,
          index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
        )
        this.currentY = res.maxY + 2
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
      }
      // this.data.order.order_parts_list.forEach((item, index) => {
      // })
    }
    return Promise.resolve()
  }
  async writeMoneyMsg() {
    this.doc.setFont('BaseFont')
    if (this.data.order.process_type === 2) {
      this.currentY += 6
      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
      }
      this.doc.setFontSize(7)
      this.doc.text('Sub-total', 100, this.currentY, null, null, 'left')
      this.doc.text(
        'USD ' + this.data.order.sub_total + '',
        190,
        this.currentY,
        null,
        null,
        'right'
      )
    } else {
      this.currentY += 6
      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
      }
      this.doc.setFontSize(7)
      this.doc.text('Parts amount', 100, this.currentY, null, null, 'left')
      this.doc.text(
        'USD ' + this.data.order.parts_amount + '',
        190,
        this.currentY,
        null,
        null,
        'right'
      )
    }

    this.currentY += 6
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }

    this.doc.text('Shipping cost', 100, this.currentY, null, null, 'left');
    const { transport_type } = this.data.order;
    this.doc.text(
      transport_type === -1
        ? 'EXW'
      : transport_type === 10?
        'FOB Shenzhen'
        : this.data.order.is_shipping_freight && this.data.order.is_shipping_freight == 1 && this.data.order.total_addition_freight == 0? 'Billed Separately': 'USD ' + this.data.order.ori_usd_freight + '',
      190,
      this.currentY,
      null,
      null,
      'right'
    )

    if (+this.data.order.total_other) {
      this.currentY += 6
      this.doc.text('Other', 100, this.currentY, null, null, 'left')
      this.doc.text(
        'USD ' + this.data.order.total_other + '',
        190,
        this.currentY,
        null,
        null,
        'right'
      )
    }

    if (+this.data.order.discount_money) {
      this.currentY += 6
      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
      }
      this.doc.text('Total discount', 100, this.currentY, null, null, 'left')
      this.doc.text(
        '-USD ' + this.data.order.discount_money + '',
        190,
        this.currentY,
        null,
        null,
        'right'
      )
    }

    if (this.data.order.process_type === 2) {
      if (([0,1,9,2,6].includes(this.data.order.first_mould_pay_type) || this.data.order.total_price > 10000)) {
        this.currentY += 6

        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }

        if (this.data.mould_data.length) {
          this.doc.text(
            `Total advance payment  \n ${this.data.order.total_advance_payment_str}`,
            100,
            this.currentY,
            null,
            null,
            'left'
          )
          this.doc.text(
            'USD ' + this.data.order.total_advance_payment + '',
            190,
            this.currentY + 1,
            null,
            null,
            'right'
          )

          this.currentY += 10

          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }

          this.doc.text(
            'Total balance payment',
            100,
            this.currentY,
            null,
            null,
            'left'
          )
          this.doc.text(
            'USD ' + this.data.order.total_balance_payment + '',
            190,
            this.currentY,
            null,
            null,
            'right'
          )
        } else {
          this.doc.text(
            `Total advance payment  \n ${this.data.order.total_advance_payment_str}`,
            100,
            this.currentY,
            null,
            null,
            'left'
          )
          this.doc.text(
            'USD ' + this.data.order.total_balance_payment + '',
            190,
            this.currentY + 1,
            null,
            null,
            'right'
          )
          this.currentY += 5
        }
      }
    }

    this.currentY += 3
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }

    this.doc.setDrawColor(255, 255, 255)
    this.doc.setFillColor(234, 84, 63)
    this.doc.rect(95, this.currentY, 100, 12.6, 'FD')
    this.doc.setFontSize(9)
    this.doc.setFont('BaseBoldFont')
    this.doc.setTextColor(255, 255, 255)
    this.doc.text(
      `Grand Total (${transport_type === -1 ? 'EXW' : transport_type === 10 ? 'FOB Shenzhen' : 'DAP'})`,
      100,
      this.currentY + 7.5,
      null,
      null,
      'left'
    )
    this.doc.text(
      'USD ' + this.data.order.total_price + '',
      190,
      this.currentY + 7.5,
      null,
      null,
      'right'
    )

    return Promise.resolve()
  }
  async writeOrderMsg() {
    const rightX = 32
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }
    const { img } = await this.getImgCanvas(RDSeal)
    this.doc.addImage(img, 17, this.currentY - 26, 50, 40, 'RDSeal', 'NONE')

    // this.doc.setFontSize(10)
    // this.doc.setFont('BaseBoldFont')
    // this.doc.setTextColor(0, 0, 0)

    // this.doc.text(
    //   'Stamp of shipper',
    //   this.baseX,
    //   this.currentY + 8,
    //   null,
    //   null,
    //   'left'
    // )

    // this.doc.setFontSize(7)

    return Promise.resolve()
  }
  // 添加新页面，并把y轴位置初始化
  async appendNewPage() {
    this.doc.addPage('a4', 'p')
    await this.writeTop()
    this.currentY = 50
    this.currentPage += 1
    this.total += 1
    return Promise.resolve()
  }
  //  二进制转base64
  blobToDataURI(blob) {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = function(e) {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })
  }
  async run() {
    const { data } = await this.request
    this.doc.addFileToVFS('BaseBoldFont.ttf', baseBoldFont)
    this.doc.addFont('BaseBoldFont.ttf', 'BaseBoldFont', 'normal')

    this.doc.addFileToVFS('BaseFont.ttf', baseFont)
    this.doc.addFont('BaseFont.ttf', 'BaseFont', 'normal')
    this.doc.addFileToVFS('fangzhengfangsong', fangzhengfangsong)
    this.doc.addFont('fangzhengfangsong', 'fangzhengfangsong', 'normal')
    this.doc.addFileToVFS('heiti', heiti)
    this.doc.addFont('heiti', 'heiti', 'normal')

    this.data = data
    if(this.data.order.process_type == 2 && this.data.order.is_return) {
      this.data.order.process_type = 1
    }
    await this.writeTop()
    const leftY = this.writeLeftMsg()
    const rightY = this.writeRightMsg()
    this.currentY = (leftY > rightY ? leftY : rightY) + 15
    await this.writeData()
    if (this.data.order.process_type === 1) {
      this.currentY += 23
      }
    await this.writeMoneyMsg()
    await this.writeOrderMsg()
    this.writeFooter(() => {
      this.doc.save(`Commercial Invoice - ${this.data.order.order_no}.pdf`)
    })
  }
}

export default PI
