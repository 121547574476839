<template>
  <el-image-viewer
    v-if="showPreview"
    ref="preview"
    :url-list="previewImages"
    :on-close="closeViewer"
    :initial-index="currentIndex"
  ></el-image-viewer>
</template>
<script>
import { Image } from "element-ui";
export default {
  components: {
    ElImageViewer: Image.components.ImageViewer
  },
  data() {
    return {
      currentIndex: 0,
      showPreview: false,
      previewImages: []
    };
  },
  watch: {
    showPreview(val) {
      if (val) {
        this.$nextTick(() => {
          console.log(this.$refs.preview)
          if (this.$refs.preview) {
            this.$refs.preview.index = this.currentIndex
          }
        })
      }
    }
  },
  methods: {
    closeViewer() {
      this.showPreview = false;
    }
  }
};
</script>
