import jsPDF from 'jspdf'
import dayjs from 'dayjs'
import logo from './logo-img.png'
import moldImg from './mould_default.png'
// import font from '@/assets/fonts/SourceHanSans-Normal.ttf'
import baseBoldFont from './chinaFont/baseBoldFont'
import baseFont from './chinaFont/baseFont'
import fangzhengfangsong from './chinaFont/fangzhengfangsong-normal'
import heiti from './chinaFont/heiti-normal'
import { multilineText, generateRandomString } from './utils'
class PI {
  constructor({ request, webUrl, ossUrl }) {
    this.request = request
    this.ossUrl = ossUrl
    this.webUrl = webUrl
    // 当前页面位置
    this.currentPage = 1
    // 总页面
    this.total = 1
    // 开头x轴
    this.baseX = 15
    // 记录零件列表中每一项x轴的位置
    this.partsTh = [this.baseX + 2, 46, 100, 137, 150, 173]
    // 记录模具列表中每一项x轴的位置
    this.moldTh = [this.baseX + 2, 38, 68, 91, 120, 137, 150, 173]
    // 记录页面所在Y轴
    this.currentY = 0
    //
    this.maxPageY = 260
    this.doc = new jsPDF()
  }
  /*
   * @param Number x , y 绘制的坐标
   * @param Number maxWidth 绘制文字的宽度
   * @param Number maxRowNum 最大行数
   * @param Number lineHeigh 行高
   */

  wrapTitleText(text, x, y, maxWidth, maxRowNum, lineHeigh = 5) {
    const arrText = text.split('')
    let line = ''
    let rowNum = 1
    for (let n = 0; n < arrText.length; n++) {
      const testLine = line + arrText[n]

      const testWidth = this.doc.getTextWidth(testLine)
      if (testWidth > maxWidth && n > 0) {
        if (rowNum >= maxRowNum) {
          const arrLine = line.split('')
          arrLine.splice(-1)
          let newTestLine = arrLine.join('')
          newTestLine += '...'
          this.doc.text(newTestLine, x, y)
          return {
            x,
            y,
            rowNum,
            index: n,
            text,
            isLast: false
          }
        }
        this.doc.text(line, x, y)
        line = arrText[n]
        y += lineHeigh
        rowNum += 1
      } else {
        line = testLine
      }
    }
    this.doc.text(line, x, y)
    return {
      x,
      y,
      rowNum,
      index: arrText.length - 1,
      text,
      isLast: true
    }
  }
  // 字符串分割指定长度的数组
  splitStr(str, length) {
    const arr = []

    let index = 0
    while (index < str.length) {
      arr.push(str.slice(index, (index += length)))
    }

    return arr
  }

  getImgCanvas(url, bg = '#ffffff') {
    return new Promise(resolve => {
      const img = new Image()
      img.src = url
      img.style.backgroundColor = bg

      img.onload = e => {
        const imgWidth = img.width
        const imgHeight = img.height

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = imgWidth
        canvas.height = imgHeight
        ctx.fillStyle = bg
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight)
        resolve({
          img: canvas.toDataURL('image/jpeg'),
          msg: {
            ratio: imgWidth / imgHeight,
            imgWidth,
            imgHeight
          }
        })
      }
    })
  }

  // 写顶部相关信息
  async writeTop() {
    try {
      const { img } = await this.getImgCanvas(logo)
      this.doc.addImage(img, this.baseX, 14, 40, 14, 'logo', 'NONE')
      this.doc.setTextColor(0, 0, 0)
      this.doc.setFont('BaseBoldFont')
      this.doc.setFontSize(16)
      this.doc.text('QUOTATION', 195, 22, { align: 'right' })
      this.doc.setFont('BaseFont')
      this.doc.setFontSize(8)
      this.doc.text(
        `# ${this.data.AttachQuoteInfo.attach_quote_no}`,
        195,
        27,
        null,
        null,
        'right'
      )

      return Promise.resolve()
    } catch (error) {}
  }
  writeLeftMsg() {
    const baseX1 = this.baseX
    const baseX2 = 38
    let varY = 50
    this.doc.setFontSize(8)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('To:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    let companyName = this.data.AttachQuoteInfo.company_name || '\\'
    this.doc.text(companyName, baseX2, varY, null, null, 'left')
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Atten:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.setTextColor(234, 84, 63)
    const atten = multilineText(
      this.doc,
      this.data.AttachQuoteInfo.consignee_name +
        ' ' +
        this.data.AttachQuoteInfo.consignee_surname,
      baseX2,
      varY,
      70,
      99
    )
    varY = atten.y + 5

    this.doc.setTextColor(0, 0, 0)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('E-mail:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const email = multilineText(
      this.doc,
      this.data.AttachQuoteInfo.email,
      baseX2,
      varY,
      70,
      99
    )
    varY = email.y + 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Tel/Skype:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.AttachQuoteInfo.phone,
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Address:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    const ship = multilineText(
      this.doc,
      this.data.AttachQuoteInfo.address +
        ', ' +
        this.data.AttachQuoteInfo.city +
        ', ' +
        this.data.AttachQuoteInfo.province +
        ', ' +
        this.data.AttachQuoteInfo.country +
        ', ' +
        this.data.AttachQuoteInfo.zipcode,
      baseX2,
      varY,
      70,
      99
    )
    // // const ship = this.wrapTitleText(
    // //   this.data.AttachQuoteInfo.address + ', ' +
    // //   this.data.AttachQuoteInfo.city + ', ' +
    // //   this.data.AttachQuoteInfo.province + ', ' +
    // //   this.data.AttachQuoteInfo.country + ', ' +
    // //   this.data.AttachQuoteInfo.zipcode
    // // , baseX2, varY, 70, 99);
    varY = ship.y
    varY += 5
    return varY
  }
  writeRightMsg() {
    const nowDate = new Date()
    const weeks = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ]
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    const baseX1 = 122
    const baseX2 = 157
    this.doc.setFontSize(8)
    let varY = 50

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Quote Date:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.AttachQuoteInfo.quote_time
        ? dayjs(this.data.AttachQuoteInfo.quote_time * 1000).format(
            'YYYY/MM/DD'
          )
        : '\\',
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    if (this.data.AttachQuoteInfo.process_type === 2) {
      this.doc.setFont('BaseBoldFont')
      this.doc.text(
        'Lead time (business day)',
        baseX1,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
      this.doc.setFont('BaseFont')
      this.doc.text('Mold:', baseX1 + 2, varY, null, null, 'left')
      this.doc.text(
        `${this.data.AttachQuoteInfo.mould_lead_time} days`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5

      this.doc.text('Part Production:', baseX1 + 2, varY, null, null, 'left')
      this.doc.text(
        `${this.data.AttachQuoteInfo.lead_time} days`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
    } else {
      this.doc.setFont('BaseBoldFont')
      this.doc.text('Lead time:', baseX1, varY, null, null, 'left')
      this.doc.setFont('BaseFont')
      this.doc.setTextColor(234, 84, 63)
      this.doc.text(
        `${this.data.AttachQuoteInfo.lead_time} Business days`,
        baseX2,
        varY,
        null,
        null,
        'left'
      )
      varY += 5
    }
    let orderBy = `(if you order \n by ${weeks[nowDate.getDay()]}, ${months[nowDate.getMonth()]} ${dayjs(nowDate).format('DD')})`;
    this.doc.setTextColor(0, 0, 0)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Ship by:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      dayjs(this.data.AttachQuoteInfo.ShipByTime * 1000).format('YYYY/MM/DD') +
      `${this.data.AttachQuoteInfo?.isPay ? '' : orderBy}`,
      baseX2,
      varY,
      {
        align: 'left',
        lineHeightFactor: 1.5
      }
    )
    this.data.AttachQuoteInfo?.isPay ? varY += 5 : varY += 10;

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Shipping Method:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.AttachQuoteInfo.transport_name,
      baseX2,
      varY,
      null,
      null,
      'left'
    )
    varY += 5

    this.doc.setFont('BaseBoldFont')
    this.doc.text('Expiry Date:', baseX1, varY, null, null, 'left')
    this.doc.setFont('BaseFont')
    this.doc.text(
      this.data.AttachQuoteInfo.fail_time
        ? dayjs(this.data.AttachQuoteInfo.fail_time * 1000).format('YYYY/MM/DD')
        : '-',
      baseX2,
      varY,
      null,
      null,
      'left'
    )

    return varY
  }
  writeMoldTbHeader() {
    const baseY = this.currentY
    this.doc.setFontSize(7)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Mold', this.moldTh[0], baseY, null, null, 'left')
    this.doc.text('Mold No.', this.moldTh[1], baseY, null, null, 'left')
    this.doc.text('Mold Material', this.moldTh[2], baseY, null, null, 'center')
    this.doc.text('Mold life', this.moldTh[3], baseY, null, null, 'left')
    this.doc.text('Cavity', this.moldTh[4], baseY, null, null, 'center')
    this.doc.text('Qty', this.moldTh[5], baseY, null, null, 'center')
    this.doc.text('Unit Price', this.moldTh[6], baseY, null, null, 'left')
    this.doc.text('Price', this.moldTh[7], baseY, null, null, 'left')
  }
  writeBlockBg(y, h, cl) {
    let color = [250, 250, 250]
    if (cl) {
      var r = parseInt(cl.substring(1, 3), 16)
      var g = parseInt(cl.substring(3, 5), 16)
      var b = parseInt(cl.substring(5, 7), 16)
      color = [r, g, b]
    }
    this.doc.setDrawColor(255, 255, 255)
    this.doc.setFillColor(...color)
    this.doc.rect(this.baseX, y, 180, h > 18 ? h : 18, 'FD')
  }
  writePartTbHeader() {
    const baseY = this.currentY
    this.doc.setFontSize(7)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Item', this.partsTh[0], baseY, null, null, 'left')
    this.doc.text('Description', this.partsTh[1], baseY, null, null, 'left')
    // this.doc.text("Remarks", this.partsTh[2], baseY, null, null, "left");
    this.doc.text('Qty', this.partsTh[3], baseY, null, null, 'center')
    this.doc.text('Unit Price', this.partsTh[4], baseY, null, null, 'left')
    this.doc.text('Price', this.partsTh[5], baseY, null, null, 'left')
  }
  async writePart(index, part, y, bg) {
    this.doc.setFontSize(6)
    this.doc.setFont('BaseBoldFont')
    this.doc.text(index + 1 + '', this.partsTh[0], y + 10, null, null, 'left')

    let img = ''
    let imgBase64 = ''
    let imgUrl = ''
    let imgH = 10

    if (part.thumbnail) {
      try {
        img = await fetch(this.ossUrl + part.thumbnail).then(res => {
          if (res.status === 200) {
            return res.blob()
          } else {
            return Promise.reject(res)
          }
        })
      } catch (error) {}
    }

    if (img) {
      imgBase64 = await this.blobToDataURI(img)
      imgUrl = await this.getImgCanvas(imgBase64, bg)
      const w = imgUrl.msg.ratio * imgH
      const ml = (15 - w) / 2
      // 辅助线
      // this.doc.setDrawColor(255, 0, 0);
      // this.doc.rect(this.partsTh[0] + 5, y + 2, 15, 10);
      const random = generateRandomString(10)
      this.doc.addImage(
        imgUrl.img,
        this.partsTh[0] + 5 + ml,
        y + 2,
        w,
        imgH,
        'part_' + random,
        'FAST'
      )
    }

    this.doc.setFontSize(4)
    this.doc.setFont('BaseFont')
    this.doc.text(
      part.length + 'x' + part.width + 'x' + part.height + ' mm',
      this.partsTh[0] + 5,
      y + 14,
      null,
      null,
      'left'
    )

    this.doc.setFontSize(6)
    const parts_name_str = multilineText(
      this.doc,
      part.parts_name,
      this.partsTh[1],
      y + 4,
      80,
      99,
      3
    );
    this.doc.setFontSize(6)

    const desc = multilineText(
      this.doc,
      this.getConfiguration(part),
      this.partsTh[1],
      parts_name_str.y + 4,
      80,
      99,
      3
    )

    this.doc.setFontSize(4)

    let descLineH = 2.5
    this.doc.text(
      `• General tolerance: ${part.tolerance}`,
      this.partsTh[1],
      desc.y + descLineH,
      null,
      null,
      'left'
    )
    descLineH += 2

    this.doc.text(
      `• Tightest tolerance: ${part.anotherTolerance}`,
      this.partsTh[1],
      desc.y + descLineH,
      null,
      null,
      'left'
    )
    descLineH += 2

    if (this.data.AttachQuoteInfo.process_type !== 2) {
      this.doc.text(
        `• Threads and tapped holes: ${part.spiricle || '-'}`,
        this.partsTh[1],
        desc.y + descLineH,
        null,
        null,
        'left'
      )
      descLineH += 2
    }
    const remark = multilineText(
      this.doc,
      `• Remarks: ${part.remark || '-'}`,
      this.partsTh[1],
      desc.y + descLineH,
      70,
      99,
      2
    )
    // this.doc.text(, this.partsTh[1], desc.y + 6, null, null, "left")

    this.doc.setFontSize(6)
    this.doc.text(part.num + '', this.partsTh[3], y + 10, null, null, 'center')

    this.doc.text(
      '$ ' + part.unit_price,
      this.partsTh[4],
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(
      '$ ' + part.total,
      this.partsTh[5],
      y + 10,
      null,
      null,
      'left'
    )

    return Promise.resolve({
      maxY: remark.y >= y + imgH + 4 ? remark.y : y + imgH + 4
    })
  }
  async writeMold(mold, y) {
    this.doc.setFontSize(6)
    this.doc.setFont('BaseBoldFont')
    const { img } = await this.getImgCanvas(moldImg, 'rgb(250, 250, 250)')
    this.doc.addImage(img, this.moldTh[0] + 2, y + 5, 10, 10, 'mold', 'NONE')
    // this.doc.addImage(moldImg, "JPEG", this.moldTh[0] + 2, y + 5, 10, 10);

    this.doc.setFontSize(6)
    this.doc.setFont('BaseFont')
    this.doc.text(mold.mould_no, this.moldTh[1], y + 10, null, null, 'left')

    this.doc.text(
      mold.mould_material,
      this.moldTh[2],
      y + 10,
      null,
      null,
      'center'
    )
    // this.doc.setDrawColor(255, 0, 0);
    // this.doc.rect(this.moldTh[2] - 6, y, 13, 18);

    this.doc.text(
      mold.mould_dura_max ? mold.mould_dura_max + ' K' : '',
      this.moldTh[3] + 3,
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(
      mold.mould_hole_num,
      this.moldTh[4],
      y + 10,
      null,
      null,
      'center'
    )
    let mouldNum = null;
    let mouldPrice = null;
    let totalMouldPrice = null;
    if(this.data.AttachQuoteInfo.is_return) {
      mouldNum = '-'
      mouldPrice = '-'
      totalMouldPrice = '-';
    }else {
      mouldNum = mold.mould_num
      mouldPrice = "$ " + mold.mould_price
      totalMouldPrice = "$ " + (+mold.mould_price * +mold.mould_num).toFixed(2)
    }
    this.doc.text(
      mouldNum + '',
      this.moldTh[5],
      y + 10,
      null,
      null,
      'center'
    )
    this.doc.text(
      mouldPrice + '',
      this.moldTh[6] + 1,
      y + 10,
      null,
      null,
      'left'
    )
    this.doc.text(
      totalMouldPrice + '',
      this.moldTh[7] + 1,
      y + 10,
      null,
      null,
      'left'
    )
    return Promise.resolve()
  }
  // 写底部页脚
  writeFooter(cb) {
    this.doc.setLineWidth(0.5)
    this.doc.setDrawColor(0, 0, 0)
    this.doc.line(195, 285, this.baseX, 285)

    this.doc.setFontSize(10)
    this.doc.setFont('BaseFont')
    this.doc.setTextColor(0, 0, 0)

    this.doc.textWithLink('www.rapiddirect.com', this.baseX, 290, {
      url: 'https://www.rapiddirect.com/'
    })

    this.doc.text(
      `Page:${this.currentPage}/${this.total}`,
      195,
      290,
      null,
      null,
      'right'
    )

    if (this.currentPage !== 1) {
      setTimeout(() => {
        // 这里需要写递归不断向上写页脚
        this.currentPage -= 1
        this.doc.movePage(this.currentPage, this.currentPage + 1)
        this.writeFooter(cb)
      }, 100)
    } else {
      // 这里代表已经写完页脚，需要把位置改为原本的位置
      this.currentPage = this.total
      this.doc.movePage(1, this.total)
      cb()
    }
  }
  async writePartBlockBg(index, part) {
    // 这里是要画背景矩形，但是由于不知道零件高度多少，所以逻辑是先画零件内容，得到高度后，再根据返回的零件数据的最高y得到矩形的高度
    let originY = this.currentY
    let maxY = 0
    const partRes = await this.writePart(
      index,
      part,
      this.currentY,
      index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
    )
    maxY = partRes.maxY
    if (maxY > this.maxPageY) {
      // 如果当前零件放不下了，那么就要新建页面
      // 把原本的零件用白色矩形隐藏起啦哎
      this.writeBlockBg(this.currentY, maxY - originY + 2, '#ffffff')
      // 添加新的页面
      await this.appendNewPage()
      // 重新再画当前的零件
      const res = await this.writePart(
        index,
        part,
        this.currentY,
        index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
      )
      // 重新赋值
      maxY = res.maxY
      originY = this.currentY
    }
    if (index % 2 === 0) {
      this.writeBlockBg(this.currentY, maxY - originY + 2)
    } else {
      this.writeBlockBg(this.currentY, maxY - originY + 2, '#ffffff')
    }
    this.currentY = originY
  }
  async writeData() {
    if (this.data.AttachQuoteInfo.process_type === 2 || this.data.AttachQuoteInfo.is_return) {
      // 注塑
      for (let i = 0; i < this.data.mouldInfo.length; i++) {
        const item = this.data.mouldInfo[i]
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
        if (i !== 0) {
          this.currentY += 10
        }
        this.writeMoldTbHeader()
        this.currentY += 3
        this.writeBlockBg(this.currentY)
        await this.writeMold(item, this.currentY)
        this.currentY += 23
        const part = this.data.AttachQuoteInfo.parts_details_info.filter(
          r => r.mould_id === item.id
        )
        for (let j = 0; j < part.length; j++) {
          const r = part[j]
          const k = j
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
          if (k === 0) {
            this.writePartTbHeader()
          }
          this.currentY += 3
          await this.writePartBlockBg(k, r)

          const res = await this.writePart(
            k,
            r,
            this.currentY,
            k % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
          )
          this.currentY = res.maxY
          if (this.currentY > this.maxPageY) {
            await this.appendNewPage()
          }
        }
        this.currentY += 8
        // if (i + 1 !== this.data.mouldInfo.length) {
        //   this.currentY -= 3
        // } else {
        //   this.currentY += 3
        // }
      }
    } else {
      // 非注塑
      this.writePartTbHeader()
      this.currentY += 3
      for (
        let i = 0;
        i < this.data.AttachQuoteInfo.parts_details_info.length;
        i++
      ) {
        const index = i
        const item = this.data.AttachQuoteInfo.parts_details_info[i]

        await this.writePartBlockBg(index, item)

        const res = await this.writePart(
          index,
          item,
          this.currentY,
          index % 2 === 0 ? 'rgb(250, 250, 250)' : '#ffffff'
        )
        this.currentY = res.maxY + 2
        if (this.currentY > this.maxPageY) {
          await this.appendNewPage()
        }
      }
      // this.data.order.order_parts_list.forEach((item, index) => {
      // })
    }
    return Promise.resolve()
  }
  async writeMoneyMsg() {
    const baseX2 = 123
    const baseX3 = 195
    const space = 7
    this.currentY += space
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }

    this.doc.setFontSize(8)
    this.doc.setFont('BaseBoldFont')
    this.doc.text('Note:', this.baseX, this.currentY, null, null, 'left')

    this.doc.setFont('BaseFont')
    if (this.data.AttachQuoteInfo.process_type === 2) {
      this.doc.setFontSize(7)
      this.doc.text('Molds amount', baseX2, this.currentY, null, null, 'left')
      this.doc.text(
        `USD ${this.data.AttachQuoteInfo.total_mould_price}`,
        baseX3,
        this.currentY,
        null,
        null,
        'right'
      )
      this.currentY += space

      if (this.currentY > this.maxPageY) {
        await this.appendNewPage()
      }
    }

    const originalPrice = Number(this.data.AttachQuoteInfo.quote_price??0)+Number(this.data.AttachQuoteInfo.total_mould_price??0)+Number(this.data.AttachQuoteInfo.freight);
    if (this.data.AttachQuoteInfo.process_type === 2) {
      this.doc.setFontSize(6)
      if ([0,1,9,2,6].includes(this.data.AttachQuoteInfo.openStatus) || originalPrice > 10000) {
        this.doc.text(
          `•  Mold payment term: ${+this.data.AttachQuoteInfo
            .mould_down_pay_rate}% in advance, the remaining ${+(
            100 - +this.data.AttachQuoteInfo.mould_down_pay_rate
          ).toFixed(2)}% due \n    upon the injection molded T1 sample approval`,
          this.baseX,
          this.currentY - 1,
          { align: 'left', lineHeightFactor: 1.5 }
        )
      } else {
        this.doc.text(
          `•  Mold payment term: Net ${{ 3: "30", 4: "60", 5: "90", 7: "15", 8: "45" }[this.data.AttachQuoteInfo.openStatus]} days`,
          this.baseX,
          this.currentY - 1,
          { align: "left", lineHeightFactor: 1.5 }
        );
      }
    }

    this.doc.setFontSize(7)
    this.doc.text('Parts amount', baseX2, this.currentY, null, null, 'left')
    this.doc.text(
      `USD ${this.data.AttachQuoteInfo.parts_total_price}`,
      baseX3,
      this.currentY,
      null,
      null,
      'right'
    )

    this.currentY += space
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }

    this.doc.setFontSize(6)
    if (this.data.AttachQuoteInfo.process_type === 2) {
      if ([0,1,9,2,6].includes(this.data.AttachQuoteInfo.openStatus) || originalPrice > 10000) {
        this.doc.text(
          '•  Part payment term: 100% after T1 sample approved',
          this.baseX,
          this.currentY,
          { align: 'left', lineHeightFactor: 1.5 }
        )
      } else {
        this.doc.text(
          `•  Part payment term: Net ${{ 3: "30", 4: "60", 5: "90", 7: "15", 8: "45" }[this.data.AttachQuoteInfo.openStatus]} days`,
          this.baseX,
          this.currentY - 2,
          { align: "left", lineHeightFactor: 1.5 }
        );
      }
    } else {
      if (this.data.AttachQuoteInfo.openStatus) {
        this.doc.text(
          `•  Part payment term: Net ${
            { 3: '30', 4: '60', 5: '90', 7: '15', 8: '45' }[
              this.data.AttachQuoteInfo.openStatus
            ]
          } days`,
          this.baseX,
          this.currentY,
          { align: 'left', lineHeightFactor: 1.5 }
        )
      } else {
        this.doc.text(
          `•  Part payment term: Payment in advance`,
          this.baseX,
          this.currentY,
          { align: 'left', lineHeightFactor: 1.5 }
        )
      }
    }

    this.doc.setFontSize(7)
    this.doc.text('Shipping cost', baseX2, this.currentY, null, null, 'left')
    const { freight,sale_freight,country,transport_type } = this.data.AttachQuoteInfo;

    let auto = false
    if(!country){
      auto = true
    }
    if(!Number(freight)){
      if(!Array.isArray(sale_freight) && sale_freight.transport_type === transport_type){
        auto = false
      }else{
        auto = true
      }
    }
    this.doc.text(
      transport_type === -1
        ? 'EXW' :
      transport_type === 10
        ? 'FOB Shenzhen'
        :auto?'Pending':`USD ${this.data.AttachQuoteInfo.freight}`,
      baseX3,
      this.currentY,
      null,
      null,
      'right'
    )
    this.currentY += space

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }

    this.doc.setFontSize(6)
    this.doc.text(
      `•  Trade Terms: ${
        transport_type === -1 ? 'EXW' : transport_type === 10 ? 'FOB Shenzhen' : 'DAP'
      }`,
      this.baseX,
      this.currentY - 2.5,
      { align: 'left', lineHeightFactor: 1.5 }
    )
    this.doc.text(
      `•  This quote is valid up to 30 days from the date issued`,
      this.baseX,
      this.currentY + 2,
      { align: 'left', lineHeightFactor: 1.5 }
    )

    this.doc.setFont('BaseBoldFont')
    this.doc.setFontSize(7)
    this.doc.text(
      `Grand Total(${
        transport_type === -1 ? 'EXW' : transport_type === 10? 'FOB Shenzhen' : 'DAP'
      })`,
      baseX2,
      this.currentY,
      null,
      null,
      'left'
    )
    this.doc.text(
      `USD ${(
        +this.data.AttachQuoteInfo.quote_price +
        +this.data.AttachQuoteInfo.freight +
        +this.data.AttachQuoteInfo.total_mould_price
      ).toFixed(2)}`,
      baseX3,
      this.currentY,
      null,
      null,
      'right'
    )
    this.currentY += space - 3
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }

    this.doc.setFontSize(6)
    this.doc.setFont('BaseFont')
    this.doc.text(
      '•  If there is any inconsistance between  3D model and 2D drawing, 3D \n    model is the master',
      this.baseX,
      this.currentY + 3,
      { align: 'left', lineHeightFactor: 1.5 }
    )

    this.doc.setDrawColor(255, 255, 255)
    this.doc.setFillColor(234, 84, 63)
    this.doc.rect(baseX2 - 3, this.currentY, 75, 10, 'FD')
    this.doc.setFontSize(9)
    this.doc.setFont('BaseBoldFont')
    this.doc.setTextColor(255, 255, 255)
    this.doc.textWithLink(
      'Check out at RapidDirect Platform',
      131,
      this.currentY + 6,
      {
        url:
          this.webUrl +
          '/quote/detail3.0?quote_no=' +
          this.data.AttachQuoteInfo.attach_quote_no.split('-')[0] +
          '&type=1'
      }
    )

    return Promise.resolve()
  }
  async writeSaleRemarks(text) {
    return new Promise(resolve => {
      this.doc.setFont('BaseFont')
      this.doc.setFontSize(6)

      const wrap = multilineText(
        this.doc,
        text,
        this.baseX + 1.5,
        this.currentY + 4,
        73,
        (280 - this.currentY) / 4,
        3.5
      )
      const baseHeight = (60 / 842) * 297
      const inputH =
        wrap.rowNum <= 5 ? baseHeight : baseHeight + (wrap.rowNum - 5) * 3.5

      this.doc.setLineWidth((1 / 595) * 210)
      this.doc.setDrawColor(208, 208, 208)
      this.doc.setFillColor(255, 255, 255)
      this.doc.roundedRect(
        this.baseX,
        this.currentY,
        (216 / 595) * 210,
        inputH,
        (3 / 842) * 297,
        (3 / 842) * 297,
        'D'
      )

      return resolve(
        Object.assign(
          {
            inputH
          },
          wrap
        )
      )
    }).then(wrap => {
      if (wrap.isLast) {
        return Promise.resolve(wrap)
      } else {
        return this.appendNewPage().then(() => {
          return this.writeSaleRemarks(
            wrap.text.slice(wrap.index, wrap.text.length)
          )
        })
      }
    })
  }
  async writeOrderMsg() {
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }
    this.doc.setTextColor(0, 0, 0)
    this.doc.setFontSize(8)
    this.doc.setFont('BaseBoldFont')

    this.doc.text('Remark:', this.baseX, this.currentY, null, null, 'left')
    this.doc.text('Signature & Date', 123, this.currentY, null, null, 'left')

    this.currentY += 4
    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }
    this.doc.setLineWidth(0.5)
    this.doc.setDrawColor(217, 217, 217)
    this.doc.line(123, this.currentY + 3, 197, this.currentY + 3)

    this.doc.setFont('BaseFont')
    this.doc.setFontSize(6)
    this.doc.text(
      '*I confirm that I am an authorised signatory of the client company.',
      123,
      this.currentY + 7,
      null,
      null,
      'left'
    )

    if (this.data.AttachQuoteInfo.sale_remarks) {
      const data = await this.writeSaleRemarks(
        this.data.AttachQuoteInfo.sale_remarks.replace('\n', '')
      )
      this.currentY = data.rowNum > 5 ? data.y - 18 : this.currentY + 0
    } else {
      this.doc.setLineWidth((1 / 595) * 210)
      this.doc.setDrawColor(208, 208, 208)
      this.doc.setFillColor(255, 255, 255)
      this.doc.roundedRect(
        this.baseX,
        this.currentY,
        (216 / 595) * 210,
        (60 / 842) * 297,
        (3 / 842) * 297,
        (3 / 842) * 297,
        'D'
      )
    }
    this.currentY += 25

    if (this.currentY > this.maxPageY) {
      await this.appendNewPage()
    }
    this.doc.setFontSize(6)
    this.doc.text(
      'We want to earn your business. If you get a lower quote, please let us \n know. We will try to beat it.',
      this.baseX,
      this.currentY,
      { align: 'left', lineHeightFactor: 1.5 }
    )

    if (this.data.AttachQuoteInfo.sale_info) {
      this.currentY += 10
      if (this.currentY > this.maxPageY + 10) {
        await this.appendNewPage()
      }
      this.doc.text(
        this.data.AttachQuoteInfo.sale_info.nickname,
        53,
        this.currentY,
        { align: 'left', lineHeightFactor: 1.5 }
      )
      this.doc.setTextColor(234, 84, 63)
      this.doc.text(
        this.data.AttachQuoteInfo.sale_info.email,
        55 +
          this.doc.getTextWidth(this.data.AttachQuoteInfo.sale_info.nickname),
        this.currentY,
        { align: 'left', lineHeightFactor: 1.5 }
      )
      this.doc.setTextColor(0, 0, 0)
    }

    return Promise.resolve()
  }
  async writeConditions() {
    this.doc.addPage('a4', 'p')
    this.currentPage += 1
    this.total += 1
    this.currentY = 22

    this.doc.setFont('BaseBoldFont')
    this.doc.setFontSize(20)
    this.doc.setTextColor(234, 84, 63)
    this.doc.text(
      'TERMS AND CONDITIONS:',
      this.baseX,
      this.currentY,
      null,
      null,
      'left'
    )
    this.currentY += 8

    this.doc.setDrawColor(0, 0, 0)
    this.doc.setLineWidth(0.5)
    this.doc.line(
      this.baseX,
      this.currentY,
      210 - this.baseX + 2,
      this.currentY
    )
    this.currentY += 1.5

    this.doc.setLineWidth(1)
    this.doc.line(
      this.baseX,
      this.currentY,
      210 - this.baseX + 2,
      this.currentY
    )
    this.currentY += 10

    this.doc.setFontSize(12)
    this.doc.setTextColor(0, 0, 0)
    this.doc.text(
      '1.Term of Sale',
      this.baseX,
      this.currentY,
      null,
      null,
      'left'
    )
    this.currentY += 9

    this.doc.setFontSize(6)
    this.doc.setFont('BaseFont')
    this.doc.text(
      'The Terms and Conditions of Sale outlined on Rapid Direct’s website (https://www.rapidirect.com) govern all sales made by  Rapid Direct for any products or services, and  \nsupersede any terms or conditions on Customer-provided Purchase Order (“PO”), or other form document exchanged between the parties. The price and delivery \n commitments made by Rapid Direct are conditioned upon Customer’s acceptance of Rapid Direct’s Terms and Conditions of Sale. Accordingly, Customer acknowledges \nand agrees that Rapid Direct accepts Customer’s PO on the express condition that Rapid Direct’s Terms and Conditions of Sale apply to the transaction, and any terms or \nconditions on Customer’s PO, other than description of the Product or Service, and their quantity, price and delivery date, are deemed rejected and are to be ignored \nunless the identical terms describing the product, service or the performance related thereto appear on this Quote.',
      this.baseX,
      this.currentY,
      {
        align: 'left',
        lineHeightFactor: 2
      }
    )
    this.currentY += 35

    this.doc.setFont('BaseBoldFont')
    this.doc.setFontSize(12)
    this.doc.setTextColor(0, 0, 0)
    this.doc.text(
      '2. Shipping Terms (Incoterms)',
      this.baseX,
      this.currentY,
      null,
      null,
      'left'
    )
    this.currentY += 9

    this.doc.setFontSize(6)
    this.doc.setFont('BaseFont')
    this.doc.text(
      'Rapid Direct’s shipping terms are either Ex Works or Duty At Place. The shipping terms for your order will be determined by the value of overseas manufactured parts and \ntheir designated end use (prototype or commercial). Any quote provided pre-sale for shipping is solely budgetary and Rapid Direct is not liable for any variance between \nthe quoted shipping cost and the final cost incurred by the Buyer. Any delays due to insufficient information provided for international shipping must be resolved by the \nBuyer.',
      this.baseX,
      this.currentY,
      {
        align: 'left',
        lineHeightFactor: 2
      }
    )
    this.currentY += 21

    this.doc.setFont('BaseBoldFont')
    this.doc.setFontSize(6)
    this.doc.text('Ex Works', this.baseX, this.currentY, null, null, 'left')

    this.doc.setFont('BaseFont')
    this.doc.text(
      'Ex Works shipping terms are from the Rapid Direct facility closest to the manufacturer or the Rapid Direct subcontractor point of manufacture.\nThe buyer will be the Importer of Record and therefore, responsible for all shipping liabilities and costs to the final destination, including any\nimport duties incurred.',
      this.baseX + 30,
      this.currentY,
      {
        align: 'left',
        lineHeightFactor: 2
      }
    )
    this.currentY += 17

    this.doc.setFont('BaseBoldFont')
    this.doc.setFontSize(6)
    this.doc.text(
      'Duty At Place',
      this.baseX,
      this.currentY,
      null,
      null,
      'left'
    )

    this.doc.setFont('BaseFont')
    this.doc.text(
      'Rapid Direct will be responsible for export custom clearance and cost. Rapid Direct will also take care of the shipping cost to the final\ndestination. Buyer will be responsible for unloading cost and import duty, taxes & customs clearance and all other costs cause in import\ncustom clearance.',
      this.baseX + 30,
      this.currentY,
      {
        align: 'left',
        lineHeightFactor: 2
      }
    )
    this.currentY += 23

    this.doc.setFont('BaseBoldFont')
    this.doc.setFontSize(12)
    this.doc.setTextColor(0, 0, 0)
    this.doc.text('3. Lead Time', this.baseX, this.currentY, null, null, 'left')
    this.currentY += 9

    this.doc.setFont('BaseFont')
    this.doc.setFontSize(6)
    this.doc.text(
      'Lead time is defined as production days following submission of both approved payment method and designs adhering to Rapid Direct’s design for manufacturability\nfeedback, which may be provided to the customer after the quotation. Shipping time not included.',
      this.baseX,
      this.currentY,
      {
        align: 'left',
        lineHeightFactor: 2
      }
    )
    this.currentY += 18

    this.doc.setFont('BaseBoldFont')
    this.doc.setFontSize(12)
    this.doc.setTextColor(0, 0, 0)
    this.doc.text(
      '4. Drawings and Specification',
      this.baseX,
      this.currentY,
      null,
      null,
      'left'
    )
    this.currentY += 7

    this.doc.setFont('BaseFont')
    this.doc.setFontSize(6)
    this.doc.text(
      'Rapid Direct uses the 3D CAD data and/or drawings, as may be provided by You, to generate Parts and tooling. 2D technical drawings will prevail over 3D CAD models only\nwith respect to parameters for tolerances and/or threads, if specified. In all other cases, 3D CAD data will take precedence during production, if these have been provided \nbefore we accepted Your Order, or unless otherwise agreed. Rapid Direct is not responsible for discrepancies between 3D CAD data and 2D technical drawings.Rapid Direct\nwill not be responsible for incorrectly designed Parts, Parts that do not assemble correctly, Parts with thick cross-sections that produce sink marks, warp, or Parts \nproduced based on incorrectly provided CAD data or technical drawings.Rapid Direct rejects all responsibility for material selection and material suitability for Your \napplication.Rapid Direct is not responsible for the fit or assembly of Parts unless specifically agreed upon in writing.Any changes to the quote in material, quantity, part \ndesign, or engineering drawing will require Rapid Direct to assess the cost and lead time impact of the customer’s desired changes and will correspondingly require a new \nrequest for quote.',
      this.baseX,
      this.currentY,
      {
        align: 'left',
        lineHeightFactor: 2
      }
    )
    this.currentY += 45

    this.doc.setFont('BaseBoldFont')
    this.doc.setFontSize(12)
    this.doc.setTextColor(0, 0, 0)
    this.doc.text(
      '5. Tolerance Standard',
      this.baseX,
      this.currentY,
      null,
      null,
      'left'
    )
    this.currentY += 7

    this.doc.setFont('BaseFont')
    this.doc.setFontSize(6)
    this.doc.text(
      'For CNC machined metal partsRapid Direct standard tolerance is ISO 2768 M. For any plastic parts and other process is ISO 2768 C. For parts requiring tolerances tighter \nthan this standard, a drawing with tolerance specifications must be uploaded and associated with the part in the quotation.',
      this.baseX,
      this.currentY,
      {
        align: 'left',
        lineHeightFactor: 2
      }
    )

    return Promise.resolve()
  }
  getConfiguration(part){
    const material = part.material_id === -1
    ? part.custom_material
    : part.material
    const smooth_spi_name  = part.smooth_spi_name 
    ?  '/' + part.smooth_spi_name
    : ''
    const finish = part.finish_data_arr.map(item => {
      return item.label && item.id ? item.label : item.name
    }).join('/')
  return part.process_name + (material?'/'+material:'') + (finish?'/'+finish:'') + smooth_spi_name
  }
  // 添加新页面，并把y轴位置初始化
  async appendNewPage() {
    this.doc.addPage('a4', 'p')
    await this.writeTop()
    this.currentY = 50
    this.currentPage += 1
    this.total += 1
    return Promise.resolve()
  }
  //  二进制转base64
  blobToDataURI(blob) {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = function(e) {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })
  }
  async run() {
    const { data } = await this.request
    this.doc.addFileToVFS('BaseBoldFont.ttf', baseBoldFont)
    this.doc.addFont('BaseBoldFont.ttf', 'BaseBoldFont', 'normal')

    this.doc.addFileToVFS('BaseFont.ttf', baseFont)
    this.doc.addFont('BaseFont.ttf', 'BaseFont', 'normal')
    this.doc.addFileToVFS('fangzhengfangsong', fangzhengfangsong)
    this.doc.addFont('fangzhengfangsong', 'fangzhengfangsong', 'normal')
    this.doc.addFileToVFS('heiti', heiti)
    this.doc.addFont('heiti', 'heiti', 'normal')
    this.data = data
    if(this.data.AttachQuoteInfo.process_type == 2 && this.data.AttachQuoteInfo.is_return) {
      this.data.AttachQuoteInfo.process_type = 1
    }
    await this.writeTop()
    const leftY = this.writeLeftMsg()
    const rightY = this.writeRightMsg()
    this.currentY = (leftY > rightY ? leftY : rightY) + 15
    await this.writeData()
    await this.writeMoneyMsg()
    this.currentY += 25
    await this.writeOrderMsg()
    await this.writeConditions()
    this.writeFooter(() => {
      this.doc.save(`${this.data.AttachQuoteInfo.attach_quote_no}.pdf`)
    })
  }
}

export default PI
