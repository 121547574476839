<template>
  <div class="box-404" v-if="error.statusCode == 404">
    <svg-icon :icon-class="'404'" :width="px2rem('400px')" :height="px2rem('244px')"></svg-icon>
    <p class="font24 c252 fw5 tip">Apologies, it seems that the page couldn't be found.</p>
    <p class="font24 c252 fw5">Please try again later.</p>
    <el-button class="btn_primary" @click="goHome">Go Back Home</el-button>
  </div>
</template>

<script>
import { px2rem } from '~/libs/tool'
export default {
  props: {
    error: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods:{
    px2rem,
    goHome(){
      this.$router.replace('/')
    }
  },
}
</script>

<style lang="scss" scoped>
.box-404{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -67px;
  background: #f6f8fa;
  .tip,.btn_primary{
    margin-top: 40px;
  }
  .btn_primary{
    font-size: 16px;
  }
}
</style>
