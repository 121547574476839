import { render, staticRenderFns } from "./newLoginModal.vue?vue&type=template&id=3ec14b39&scoped=true"
import script from "./newLoginModal.vue?vue&type=script&lang=js"
export * from "./newLoginModal.vue?vue&type=script&lang=js"
import style0 from "./newLoginModal.vue?vue&type=style&index=0&id=3ec14b39&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec14b39",
  null
  
)

export default component.exports